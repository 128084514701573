<template>
  <div class="gl-axis-chart__wrap">
    <div
      class="gl-axis-chart__toggles"
    >
      <div
        v-for="(series, index) in seriesData"
        :key="index"
        class="gl-axis-chart__toggles--item"
        :style="{
          'background-color': hex2rgba(getColor(series.tag), 0.25),
          border: `1px solid ${getColor(series.tag)}`,
        }"
        @click="toggleSeries(series.tag)"
      >
        <div class="gl-axis-chart__toggles--total">
          {{ series.totalRequests }}
        </div>
        <gl-checkbox
          v-model="seriesVisibility[series.tag]"
          class="gl-axis-chart__toggles--label"
          :disabled="loading"
          :label="series.label"
          @input="toggleSeries(series.tag)"
        />
      </div>
    </div>

    <div class="gl-axis-chart__controls-wrap">
      <div class="gl-axis-chart__controls">
        <div
          v-for="days in daysControls"
          :key="days.value"
          class="gl-axis-chart__controls--item"
          :class="{
            'gl-axis-chart__controls--item-active': days.value === currentRange,
            'gl-axis-chart__controls--loading': loading,
          }"
          @click="updateTimeRange(days.value)"
        >
          {{ days.label }}
        </div>
      </div>
      <div class="chart-container">
        <div
          v-if="isDataEmpty"
          class="gl-axis-chart__empty"
        >
          No Data Available
        </div>
        <div
          ref="chart"
          class="chart"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import * as echarts from 'echarts';
// Utils
import { hex2rgba } from '@/utils/cytoskape-ui-rules';
// Components
import GlCheckbox from '@/components/gl-checkbox.vue';
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { formatDate } from '@/utils/format-date'

export default {
  name: 'AreaChart',
  components: { GlCheckbox },
  mixins: [deviceWidthMixin],
  props: {
    usageStatistics: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oneDay: 24 * 3600 * 1000,
      chartInstance: null,
      seriesVisibility: {},
      daysControls: [
        { label: '7d', value: 7 },
        { label: '14d', value: 14 },
        { label: '1m', value: 30 },
        { label: '3m', value: 90 },
        { label: 'Max', value: null },
      ],
      currentRange: null,
      isDataUpdating: false,
    };
  },
  computed: {
    seriesData() {
      return this.usageStatistics.map((stat) => ({
        tag: stat.tag,
        counts: stat.counts.map((item) => [item.timestamp, item.value]),
        label: this.getLabel(stat.tag),
        labelTooltip: this.getTooltipLabel(stat.tag),
        totalRequests: stat.totalCount || 0,
      }));
    },
    isDataEmpty() {
      return this.seriesData.every(series => series.counts.length === 0);
    }
  },
  watch: {
    usageStatistics: {
      handler() {
        if (!this.isDataUpdating) {
          this.initSeriesVisibility();
          this.updateTimeRange(this.currentRange, true);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initSeriesVisibility();
    this.initChart();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    formatDate,
    hex2rgba,
    initSeriesVisibility() {
      this.usageStatistics.forEach((stat) => {
        this.$set(this.seriesVisibility, stat.tag, true);
      });
    },
    initChart() {
      const chartDom = this.$refs.chart;
      this.chartInstance = echarts.init(chartDom);
      this.updateTimeRange(this.currentRange, true);
    },
    updateTimeRange(range = 30, isInitialLoad = false) {
      if (!isInitialLoad) {
        this.isDataUpdating = true;
      }
      this.currentRange = range;
      const now = +new Date();

      const startAtDate = new Date(now - range * this.oneDay);
      startAtDate.setHours(0, 0, 0, 0);

      const start = range ? startAtDate.getTime() : 0;

      if (!isInitialLoad) {
        this.$emit('change-range', { startAt: range ? start : undefined });
      }

      const series = this.seriesData.map((seriesItem) => {
        const filteredData = seriesItem.counts.filter((d) => range ? d[0] >= start : true);

        return {
          name: seriesItem.labelTooltip,
          type: 'line',
          symbol: 'none',
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: hex2rgba(this.getColor(seriesItem.tag), 0.6),
                },
                {
                  offset: 0.93,
                  color: hex2rgba(this.getColor(seriesItem.tag), 0.1),
                },
              ],
            },
          },
          itemStyle: {
            color: this.getColor(seriesItem.tag),
          },
          data: this.seriesVisibility[seriesItem.tag] ? filteredData.map((d) => d[1]) : [],
        };
      });

      const option = {
        grid: {
          left: this.isDesktop ? 40 : 30,
          right: this.isDesktop ? 40 : 20,
          top: 25,
          bottom: 50,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          position(pos, params, dom, rect, size) {
            const { viewSize } = size;
            const { contentSize } = size;

            const xPos = pos[0] + contentSize[0] > viewSize[0] ? viewSize[0] - contentSize[0] : pos[0];
            const yPos = pos[1] + contentSize[1] > viewSize[1] ? viewSize[1] - contentSize[1] : pos[1];

            return [xPos, yPos];
          },
          backgroundColor: 'rgba(255, 255, 255, 1)',
          formatter(params) {
            const tooltipContent = `
        <div style="
          display: inline-flex;
          /*height: 76px;*/
          padding: 8px 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 3px;
          background: var(--white, #FFF);
          box-shadow: 0 0 8px 0 rgba(166,149,149,0.5);
        ">
          <div style="
            color: var(--space-cadet);
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
          ">
            ${params[0].axisValueLabel}
          </div>
          ${params.map(param => `
            <div style="
              color: var(--dark-grey-6-e);
              font-family: Montserrat;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              text-transform: uppercase;
            ">
              ${param.seriesName}: <span style="font-size: 14px; font-weight: 500; color: ${param.color};">${param.value}</span>
            </div>
          `).join('')}
        </div>
      `;
            return tooltipContent;
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.getXAxisData(start),
          axisLabel: {
            textStyle: {
              fontFamily: 'Montserrat, sans-serif',
              color: '#D3D3D3',
            },
          },
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          position: 'right',
          axisLabel: {
            textStyle: {
              fontFamily: 'Montserrat, sans-serif',
              color: '#D3D3D3',
            },
          },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            filterMode: 'none'
          },
          {
            start: 0,
            end: 100,
            textStyle: {
              color: 'transparent'
            },
            filterMode: 'none'
          },
        ],
        series,
      };

      this.chartInstance.setOption(option);
      this.handleResize();

      if (!isInitialLoad) {
        this.isDataUpdating = false;
      }
    },
    toggleSeries(tag) {
      if (this.loading || !tag) return
      this.$set(this.seriesVisibility, tag, !this.seriesVisibility[tag]);
      this.updateSeriesVisibility();
    },
    updateSeriesVisibility() {
      const series = this.seriesData.map((seriesItem) => {
        const filteredData = seriesItem.counts.filter((d) => this.currentRange ? d[0] >= +new Date() - this.currentRange * this.oneDay : true);

        return {
          name: seriesItem.tag,
          type: 'line',
          symbol: 'none',
          areaStyle: {},
          itemStyle: {
            color: this.getColor(seriesItem.tag),
          },
          data: this.seriesVisibility[seriesItem.tag] ? filteredData.map((d) => d[1]) : [],
        };
      });

      this.chartInstance.setOption({
        series,
      });
    },
    handleResize() {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    },
    getColor(tag) {
      const colors = {
        REPORT_GET: '#FDE473',
        MONITORING_ADD: '#74AA4C',
      };
      return colors[tag] || '#4c68de';
    },
    getLabel(tag) {
      const label = {
        REPORT_GET: 'Total Risk Assessment requests',
        MONITORING_ADD: 'Total Monitoring requests',
      };
      return label[tag] || '--';
    },
    getTooltipLabel(tag) {
      const label = {
        REPORT_GET: 'Risk Assessment requests',
        MONITORING_ADD: 'Monitoring requests',
      };
      return label[tag] || '--';
    },
    getXAxisData(start) {
      if (this.seriesData.length === 0 || this.seriesData[0].counts.length === 0) return [];
      return this.seriesData[0].counts.filter((d) => start ? d[0] >= start : true).map((d) => formatDate(new Date(d[0]), 'dd.MM.yyyy'));
    },
  },
};
</script>

<style scoped>
.gl-axis-chart__wrap {
  padding: 28px;
}

.gl-axis-chart__toggles {
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;
}

.gl-axis-chart__toggles--item {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.gl-axis-chart__toggles--total {
  color: var(--space-cadet, #091540);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gl-axis-chart__toggles--label {
  color: var(--space-cadet, #091540);
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}

.gl-axis-chart__controls {
  border-radius: 6px;
  background: var(--light-pale-blue, #F0F2F9);
  display: inline-block;
  padding: 2px;
}

.gl-axis-chart__empty {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;

  color: var(--dark-grey-6-e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.gl-axis-chart__controls--item {
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  color: var(--dark-grey-6-e);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.gl-axis-chart__controls--item-active {
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  color: var(--space-cadet);
  background: var(--white);

  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(14, 11, 61, 0.10);
}

.gl-axis-chart__controls--loading {
  opacity: 0.65;
  cursor: not-allowed;
}

.chart-container {
  width: 100%;
  height: 490px;
  position: relative;
}

.gl-axis-chart__controls-wrap {
  padding: 16px;
  border: 1px solid var(--pale-grey);
  border-radius: 8px;
  background: #FFF;
}

.chart {
  width: 100%;
  height: 100%;
}

.controls,
.toggles {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.controls div,
.toggles div {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  user-select: none;
  transition: background-color 0.3s;
}

.controls div:hover,
.toggles div:hover {
  background-color: #e0e0e0;
}

@media (max-width: 767px) {
  .gl-axis-chart__controls {
    display: flex;
    width: 100%;
  }

  .gl-axis-chart__controls--item {
    width: 100%;
  }

  .gl-axis-chart__toggles {
    flex-direction: column;
    width: 100%;
  }

  .gl-axis-chart__toggles--item {
    width: 100%;
  }
}
</style>
