var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl-axis-chart__wrap"},[_c('div',{staticClass:"gl-axis-chart__toggles"},_vm._l((_vm.seriesData),function(series,index){return _c('div',{key:index,staticClass:"gl-axis-chart__toggles--item",style:({
        'background-color': _vm.hex2rgba(_vm.getColor(series.tag), 0.25),
        border: `1px solid ${_vm.getColor(series.tag)}`,
      }),on:{"click":function($event){return _vm.toggleSeries(series.tag)}}},[_c('div',{staticClass:"gl-axis-chart__toggles--total"},[_vm._v(" "+_vm._s(series.totalRequests)+" ")]),_c('gl-checkbox',{staticClass:"gl-axis-chart__toggles--label",attrs:{"disabled":_vm.loading,"label":series.label},on:{"input":function($event){return _vm.toggleSeries(series.tag)}},model:{value:(_vm.seriesVisibility[series.tag]),callback:function ($$v) {_vm.$set(_vm.seriesVisibility, series.tag, $$v)},expression:"seriesVisibility[series.tag]"}})],1)}),0),_c('div',{staticClass:"gl-axis-chart__controls-wrap"},[_c('div',{staticClass:"gl-axis-chart__controls"},_vm._l((_vm.daysControls),function(days){return _c('div',{key:days.value,staticClass:"gl-axis-chart__controls--item",class:{
          'gl-axis-chart__controls--item-active': days.value === _vm.currentRange,
          'gl-axis-chart__controls--loading': _vm.loading,
        },on:{"click":function($event){return _vm.updateTimeRange(days.value)}}},[_vm._v(" "+_vm._s(days.label)+" ")])}),0),_c('div',{staticClass:"chart-container"},[(_vm.isDataEmpty)?_c('div',{staticClass:"gl-axis-chart__empty"},[_vm._v(" No Data Available ")]):_vm._e(),_c('div',{ref:"chart",staticClass:"chart"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }