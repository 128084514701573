<template>
  <GlPageWrap
    class="users-page-wrapper"
  >
    <div
      class="flex align-center mb-3 pointer"
      @click="$router.push({ name: 'users' })"
    >
      <gl-icon
        class="mr-1"
        :height="24"
        link
        name="left"
        :width="24"
      />
      <div class="back-link">
        User Management
      </div>
    </div>
    <div class="user-profile-main-info__wrapper mb-4">
      <div class="flex space-between align-center">
        <div class="flex align-center m-column">
          <div class="user-profile__email-label ellipsis mr-2 m-mb-2">
            {{ user.email }}
          </div>
          <div
            class="user-profile__status"
            :class="[{'user-profile__status--disabled': user.disabled}, {'user-profile__status--active': !user.disabled}]"
          >
            {{ user.disabled ? 'disabled' : 'active' }}
          </div>
        </div>
        <gl-icon
          class="pointer"
          :height="16"
          name="more-new"
          :width="16"
          @click="ctxOpenHandler(user, $event)"
        />
      </div>
      <hr class="mt-4 mb-4 horizontal-divider grey-text-cotton-f5">
      <div class="user-grid">
        <div class="user-card">
          <div class="user-card__label mb-2">
            User Company
          </div>
          <div class="user-card__value ellipsis">
            {{ user.companyName || '-' }}
          </div>
        </div>
        <div class="user-card">
          <div class="user-card__label mb-2">
            User Role
          </div>
          <div class="user-card__value">
            {{ user.role ? capitalizeFirstLetter(getLabelRole(user.role)) : '-' }}
          </div>
        </div>
        <div class="user-card">
          <div class="user-card__label mb-2">
            User ID
          </div>
          <div class="user-card__value ellipsis">
            {{ user._id }}
          </div>
        </div>
        <div class="user-card">
          <div class="user-card__label mb-2">
            No. of Requests
          </div>
          <div class="user-card__value">
            <span v-if="!user.requestsTotal">
              Unlimited
            </span>
            <div v-else>
              <span :class="[{ 'warning-text': isWithinPercentage(Math.max(user.requestsTotal - user.requestsMade, 0), user.requestsTotal) }]">
                {{ toComaSeparate(Math.max(user.requestsTotal - user.requestsMade, 0)) }}
              </span> /
              <span>{{ toComaSeparate(user.requestsTotal) }}</span>
            </div>
          </div>
        </div>
        <div class="user-card mb-2">
          <div class="user-card__label">
            Expiration Date
          </div>
          <div class="user-card__value">
            {{ user.activeTo ? formatDate(user.activeTo, 'dd.MM.yyyy') : '-' }}
          </div>
        </div>
        <div class="user-card mb-2">
          <div class="user-card__label">
            Created By
          </div>
          <div
            class="user-card__value link-text"
            @click="goMailto(user.createdByUser && user.createdByUser.email)"
          >
            {{ (user.createdByUser && user.createdByUser.email) || '-' }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hasAvailableUserStats"
      class="page-wrapper__simple"
    >
      <o-tabs
        v-model="activeTab"
        class="activity-tabs-wrapper"
      >
        <o-tab-item
          v-if="featureAccess('USER_ACTIVITY')"
          label="Activity log"
          :value="'activity'"
        >
          <ActivityLog
            v-if="user._id"
            :user-id="user._id"
          />
        </o-tab-item>
        <o-tab-item
          v-if="featureAccess('USER_STATS')"
          label="Statistic"
          :value="'statistic'"
        >
          <UserStats
            v-if="user._id && activeTab === 'statistic'"
            :user-id="user._id"
          />
        </o-tab-item>
      </o-tabs>
    </div>
    <ContextMenu
      ref="ctx"
      v-model="showContextMenu"
      :can-view="false"
      :data="ctxProfileData"
      :position="contextMenuPosition"
      @delete="userDelete(user)"
      @edit="updateUser(user)"
      @reset-password="handleResetPassword(user)"
    />
    <createUserModal
      v-if="createUser"
      v-model="createUser"
      :active-to="selectUser.activeTo || null"
      :company-name="selectUser.companyName"
      :email="selectUser.email"
      :is-create="isCreate"
      :requests-total="selectUser.requestsTotal || null"
      :role="selectUser.role"
      @close="createUser = false"
      @update="update($event)"
    />
    <resetPasswordModal
      v-model="resetPasswordModal"
      :email="selectUser.email"
      @close="resetPasswordModal = false"
      @submit="reset"
    />
    <deleteUserModal
      v-model="deleteModal"
      :data="selectUser"
      @close="deleteModal = false"
      @submit="removeUser"
    />
  </GlPageWrap>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon.vue'
import GlPageWrap from '@/components/layout/gl-page-wrap.vue'
import ActivityLog from '@/pages/users/components/ActivityLog.vue'
import UserStats from '@/pages/users/components/UserStats.vue'
import ContextMenu from '@/pages/users/components/ContextMenu.vue'
import deleteUserModal from '@/pages/users/modals/deleteUserModal.vue'
import createUserModal from '@/pages/users/modals/createUserModal.vue'
import resetPasswordModal from '@/pages/users/modals/resetPasswordModal.vue'
// Vuex
import { mapActions } from 'vuex'
// Utils
import { formatDate } from "@/utils/format-date";
import { getLabelRole, isWithinPercentage } from '@/utils/profile'
import { toComaSeparate } from '@/utils/formatNumber'
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { goMailto } from '@/utils/go-to-route'
import { featureAccess } from '@/utils/accesses'

export default {
  components: {
    UserStats,
    resetPasswordModal,
    createUserModal,
    deleteUserModal,
    ContextMenu,
    GlIcon,
    GlPageWrap,
    ActivityLog
  },
  data() {
    return {
      user: {},
      activeTab: 'activity',
      showContextMenu: false,
      contextMenuPosition: { x: 0, y: 0 },
      ctxProfileData: null,
      isCreate: true,
      createUser: false,
      resetPasswordModal: false,
      deleteModal: false,
      selectUser: {},
    }
  },
  computed: {
    hasAvailableUserStats() {
      return this.featureAccess('USER_ACTIVITY') || this.featureAccess('USER_STATS')
    }
  },
  created() {
    if (!this.featureAccess('USER_ACTIVITY') && this.featureAccess('USER_STATS')) {
      this.activeTab = 'statistic'
    }
    this.getUserDetails();
  },
  methods: {
    featureAccess,
    getLabelRole,
    goMailto,
    capitalizeFirstLetter,
    toComaSeparate,
    isWithinPercentage,
    ...mapActions({
      logout: 'user/logout',
      editUser: 'users/editUser',
      deleteUser: 'users/deleteUser',
      resetPassword: 'users/resetPassword',
      getUser: 'users/getUser',
    }),
    formatDate,
    handleResetPassword(data) {
      this.selectUser = data
      this.resetPasswordModal = !this.resetPasswordModal
    },
    userDelete(user) {
      this.deleteModal = true
      this.selectUser = user
    },
    updateUser(user) {
      this.isCreate = false
      this.selectUser = user
      this.createUser = true
      this.showContextMenu = false
    },
    reset(email) {
      if (email) {
        this.resetPassword(email).then(() => {
          this.resetPasswordModal = false
          this.$toasted.global.success({ message: `${email} password was successfully reseted` })
        })
      }
    },
    async removeUser(userId) {
      const id = this.$route.params.id || userId

      if (userId) {
        await this.deleteUser(id).then(() => {
          this.deleteModal = false
          this.$toasted.global.success({ message: 'The user was successfully deleted' })
        }).finally(() => {
          this.$router.push({ name: 'users' })
        })
      }
    },
    async update(data) {
      const id = this.$route.params.id
      await this.editUser({id, body: {
          ...data,
          activeTo: data.activeTo || 0
        }}).then(() => {
        this.selectUser = {}
        this.createUser = false
      })
      await this.getUserDetails()
    },
    ctxOpenHandler(val, e) {
      const { x, y } = e
      this.contextMenuPosition = { x, y }

      this.showContextMenu = false
      this.ctxProfileData = val || null
      this.showContextMenu = true
    },
    async getUserDetails() {
      const userId = this.$route.params.id;

      const { data } = await this.getUser(userId);
      this.user = data;
    }
  }
}
</script>
<style>
.user-profile-main-info__wrapper {
  padding: 24px;
  background: var(--white);
}
.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
}

.user-card {
  background-color: inherit;
  //padding: 15px;
  border-radius: 5px;
}

.user-card__label {
  color: var(--dark-grey-6-e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}

.user-profile__email-label {
  color: var(--space-cadet);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user-card__value {
  color: var(--space-cadet);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-profile__status {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 3px;

  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-transform: uppercase;
}

.user-profile__status--disabled {
  background: var(--scarlet-red);
}
.user-profile__status--active {
  background: var(--green);
}

.user-card div {
  margin-bottom: 5px;
}

.activity-tabs-wrapper .o-tabs__nav {
  border-bottom: 1px solid var(--pale-grey);
}

@media only screen and (max-width: 768px) {
  .user-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .user-profile__email-label {
    font-size: 18px;
  }
}
</style>
